// Javascript
import '../css/main.css'
import conditioner, { idle } from '@parallaxagency/conditioner'
import AOS from 'aos'
import 'lazysizes'
import 'lazysizes/plugins/respimg/ls.respimg'

/* Conditioner - context aware component loading */
conditioner.addPlugin({
    moduleSetName: (name) => `./conditioner/${name}.js`,
    moduleGetConstructor: (module) => module.default,
    moduleImport: (name) =>
        import(/* webpackChunkName: 'conditioner' */ `${name}`),
})
conditioner.addPlugin(idle)

/* Loading components regardless of context */
const loadLib = (lib) => {
    if (typeof lib === 'function') lib()
}

const init = () => {
    AOS.init({
        once: true,
        startEvent: 'DOMContentLoaded',
        debounceDelay: 50,
        throttleDelay: 50,
        offset: 220,
        duration: 800,
        delay: 25,
        easing: 'ease-out-quad',
        anchorPlacement: 'top-bottom',
    })
    // if (process.env.NODE_ENV !== 'production') {
    //     import(
    //         /* webpackChunkName: "DevGridOverlay" */ './components/dev-grid-overlay.js'
    //     ).then(({ DevGridOverlay }) => {
    //         loadLib(DevGridOverlay)
    //     })
    // }
}

window.addEventListener('DOMContentLoaded', (e) => {
    conditioner.hydrate(document.documentElement)
    init()
})
